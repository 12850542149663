import { FilterDropdownListType, FilterOption } from 'types/common';
import { DateTenantFilter } from 'types/dateTenantFilter.model';

export const dateTimefilter: DateTenantFilter = {
  requestFilter: {
    filters: [
      {
        name: '1D',
        id: '1D',
        applied: false,
        value: null
      },
      {
        name: '7D',
        id: '7D',
        applied: false,
        value: null
      },
      {
        name: '30D',
        id: '30D',
        applied: true,
        value: null
      },
      {
        name: 'MTD',
        id: 'MTD',
        applied: false,
        value: null
      },
      {
        name: 'YTD',
        id: 'YTD',
        applied: false,
        value: null
      },
      {
        name: 'CUSTOM',
        id: 'CUSTOM',
        applied: false,
        value: {
          fromTimestamp: undefined,
          toTimestamp: undefined
        }
      },
      {
        name: 'TENANT',
        id: 'TENANT',
        applied: true,
        value: {
          tenantNames: []
        }
      }
    ]
  }
};

export const filterKeys: { [key: string]: string } = {
  reboot: 'reboot',
  sort: 'sort',
  sortUnvm: 'sortUnvm'
};

export const filterDropdownList: FilterDropdownListType = {
  sort: [
    { UTIL_HIGH: 'Utilization : High to Low' },
    { UTIL_LOW: 'Utilization : Low to High' },
    { SAVINGS_HIGH: 'Savings : High to Low' },
    { SAVINGS_LOW: 'Savings : Low to High' }
  ],
  sortUnvm: [
    { UNMANAGED_UTIL_HIGH: 'Utilization : High to Low' },
    { UNMANAGED_UTIL_LOW: 'Utilization : Low to High' },
    // { POTENTIAL_SAVINGS_HIGH: 'Potential Savings : High to Low' },
    // { POTENTIAL_SAVINGS_LOW: 'Potential Savings : Low to High' }
  ],
  reboot: [
    { MULTIPLE_INSTANCE_ONBOARD_DATA_DISK_REBOOT: 'Volumes Requiring Reboot' },
    { MULTIPLE_INSTANCE_ONBOARD_DATA_DISK_NO_REBOOT: 'Volumes Not Requiring Reboot' }
  ]
};

export const filterDropdownDefaultText: FilterOption = {
  sort: 'Sort by',
  sortUnvm: 'Sort by:Default',
  reboot: 'Filter instance for bulk onbaording'
};
